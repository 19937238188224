import React, {useEffect, useState} from 'react';
import './ClickableImage.css';
import { memo } from "react";
const ClickableImage = ({ src, alt, state, title}) => {
    console.log("Clickable image rendered")
  const [borderActive, setBorderActive] = useState(state);

   useEffect(() => {
        console.log('Clickable image mounted');
         return () => {
    console.log('Clickable image unmounted');
  };
    }, []);


  const handleClick = () => {
      setBorderActive(!borderActive);
  };

  const imageClasses = borderActive ? 'imageLogo imagec active' : 'imageLogo imagec';
  const textgold = borderActive ? 'gold texticon' : 'notgold texticon';
  return (
      <div>
          <img
      src={src}
      alt={alt}
      className={imageClasses}
      onClick={handleClick}
    />
          <div className={textgold}>{title}</div>
      </div>
  );
};

export default memo(ClickableImage);
