import React from "react";
import "./SiteMap.css";
import {SocialIcon} from 'react-social-icons';
import ComingSoon from "./ComingSoon";
import Creation from "../pages/Creation/Creation";
import ImageGallery from "../pages/ImageGallery/ImageGallery";
import Catalog from "../pages/Catalog/Catalog";
import About from "../pages/About/About";
import { memo } from "react";
// 0: <Creation changeTab={changeTab} siteMapRef={siteMapRef}/>, 1: <ImageGallery/>, 2: <Catalog/>, 3: <About/>, 4: <ComingSoon/>
const SiteMap = ({changeTab, classname}) => {
    return (<div className={classname}>

        <div style={{"margin-top": "auto", "margin-bottom": "auto"}} className={"sitemap2"}>
            Aibyu © 2022 – 2023
        </div>
        <div className="categories">
            <div className="category">
                <div className={"text"}>Create</div>
                <a href="/"
                   onClick={(e) => {
                       e.preventDefault();
                       changeTab(<Creation changeTab={changeTab}/>, 0);
                       window.scrollTo({
                           top: 0,
                           left: 0,
                           behavior: 'instant'
                       });
                   }}
                >Studio</a>
                <a href="/"
                   onClick={(e) => {
                       e.preventDefault();
                       changeTab(<ImageGallery/>, 1);
                       window.scrollTo({
                           top: 0,
                           left: 0,
                           behavior: 'instant'
                       });
                   }}
                >Community</a>
                <a href="/"
                   onClick={(e) => {
                       e.preventDefault();
                       changeTab(<Catalog/>, 2);
                       window.scrollTo({
                           top: 0,
                           left: 0,
                           behavior: 'instant'
                       });
                   }}
                >Catalog</a>
            </div>
            <div className="category">
                <div className={"text"}>Learn</div>
                <a href="/"
                   onClick={(e) => {
                       e.preventDefault();
                       changeTab(<ComingSoon/>, 5);
                       window.scrollTo({
                           top: 0,
                           left: 0,
                           behavior: 'instant'
                       });
                   }}
                >
                    How To
                </a>
                <a href="/"
                   onClick={(e) => {
                       e.preventDefault();
                       changeTab(<ComingSoon/>, 5);
                       window.scrollTo({
                           top: 0,
                           left: 0,
                           behavior: 'instant'
                       });
                   }}
                >Generative AI</a>
            </div>
            <div className="category">
                <div className={"text"}>Information</div>
                <a href="/"
                   onClick={(e) => {
                       e.preventDefault();
                       changeTab(<About changeTab={changeTab}/>, 3);
                       window.scrollTo({
                           top: 0,
                           left: 0,
                           behavior: 'instant'
                       });
                   }}
                >About</a>
                <a href="/"
                   onClick={(e) => {
                       e.preventDefault();
                       changeTab(<About changeTab={changeTab}/>, 3);
                       window.scrollTo({
                           top: 0,
                           left: 0,
                           behavior: 'instant'
                       });
                   }}
                >Contact</a>
            </div>
        </div>
        <div className="social-media">
            <SocialIcon url="https://twitter.com/AibyuLabs?s=20" target="_blank" rel="noopener noreferrer"/>
            <SocialIcon url="https://www.instagram.com/aibyulabs/" target="_blank" rel="noopener noreferrer"/>
            <SocialIcon url="mailto:aibyulabs@gmail.com " target="_blank" rel="noopener noreferrer"/>
        </div>
        <div style={{"margin-top": "auto", "margin-bottom": "auto"}} className={"sitemap3"}>
            Aibyu © 2022 – 2023
        </div>
    </div>);
};

export default memo(SiteMap);