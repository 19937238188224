import React from 'react'
import * as ReactDOMClient from 'react-dom/client';
import './Project.css';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider,} from "react-router-dom";
import Login from "./pages/Login/Login";
import ErrorPage from "./pages/Error/Error";
import App from "./pages/App/App";
import {Provider} from 'react-redux'
import {createStore} from 'redux'
import 'bootstrap/dist/css/bootstrap.css';

function todos(state = [], action) {
    switch (action.type) {
        case 'ADD_TODO':
            return state.concat([action.text]);
        default:
            return state
    }
}

function images(state = [], action) {
    switch (action.type) {
        case 'ADD_TODO':
            return state.concat([action.text]);
        default:
            return state
    }
}

export const store = createStore(todos, []);
export const store2 = createStore(images, []);
export const base_url = 'https://api.aibyulabs.com'
// export const base_url = 'http://127.0.0.1:4999'; //

export const prompt_dict = {
    "Banana armchair":
        {
            "images": ['https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 18:01:02.671815_0.png', 'https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 18:01:02.671815_1.png', 'https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 18:01:02.671815_2.png', 'https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 18:01:02.671815_3.png'],
            "compressed_images": ['https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 18:01:02.671815_0_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 18:01:02.671815_1_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 18:01:02.671815_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 18:01:02.671815_3_compressed.png']
        },
    "A digital Illustration of the a purely mechanical television, 4k, detailed, fantasy vivid colors":
        {
            "images":
                ['https://s3.amazonaws.com/aibyu/user_images/cdb6b655-2e82-4179-b1e3-7592b031b1b8/cdb6b655-2e82-4179-b1e3-7592b031b1b8_2023-03-17 23:15:06.299411_2.png', 'https://s3.amazonaws.com/aibyu/user_images/cdb6b655-2e82-4179-b1e3-7592b031b1b8/cdb6b655-2e82-4179-b1e3-7592b031b1b8_2023-03-17 23:15:06.299411_3.png', 'https://s3.amazonaws.com/aibyu/user_images/cdb6b655-2e82-4179-b1e3-7592b031b1b8/cdb6b655-2e82-4179-b1e3-7592b031b1b8_2023-03-17 23:15:06.299411_4.png', 'https://s3.amazonaws.com/aibyu/user_images/cdb6b655-2e82-4179-b1e3-7592b031b1b8/cdb6b655-2e82-4179-b1e3-7592b031b1b8_2023-03-17 23:15:06.299411_5.png'],
            "compressed_images":
                ['https://s3.amazonaws.com/aibyu/user_images/cdb6b655-2e82-4179-b1e3-7592b031b1b8/cdb6b655-2e82-4179-b1e3-7592b031b1b8_2023-03-17 23:15:06.299411_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/cdb6b655-2e82-4179-b1e3-7592b031b1b8/cdb6b655-2e82-4179-b1e3-7592b031b1b8_2023-03-17 23:15:06.299411_3_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/cdb6b655-2e82-4179-b1e3-7592b031b1b8/cdb6b655-2e82-4179-b1e3-7592b031b1b8_2023-03-17 23:15:06.299411_4_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/cdb6b655-2e82-4179-b1e3-7592b031b1b8/cdb6b655-2e82-4179-b1e3-7592b031b1b8_2023-03-17 23:15:06.299411_5_compressed.png']
        },
    "Psychedelic anteater":
        {
            "images":
                ['https://s3.amazonaws.com/aibyu/user_images/cdb6b655-2e82-4179-b1e3-7592b031b1b8/cdb6b655-2e82-4179-b1e3-7592b031b1b8_2023-03-17 23:22:03.813455_0.png', 'https://s3.amazonaws.com/aibyu/user_images/cdb6b655-2e82-4179-b1e3-7592b031b1b8/cdb6b655-2e82-4179-b1e3-7592b031b1b8_2023-03-17 23:22:03.813455_1.png', 'https://s3.amazonaws.com/aibyu/user_images/cdb6b655-2e82-4179-b1e3-7592b031b1b8/cdb6b655-2e82-4179-b1e3-7592b031b1b8_2023-03-17 23:22:03.813455_2.png', 'https://s3.amazonaws.com/aibyu/user_images/cdb6b655-2e82-4179-b1e3-7592b031b1b8/cdb6b655-2e82-4179-b1e3-7592b031b1b8_2023-03-17 23:22:03.813455_4.png'],
            "compressed_images":
                ['https://s3.amazonaws.com/aibyu/user_images/cdb6b655-2e82-4179-b1e3-7592b031b1b8/cdb6b655-2e82-4179-b1e3-7592b031b1b8_2023-03-17 23:22:03.813455_0_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/cdb6b655-2e82-4179-b1e3-7592b031b1b8/cdb6b655-2e82-4179-b1e3-7592b031b1b8_2023-03-17 23:22:03.813455_1_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/cdb6b655-2e82-4179-b1e3-7592b031b1b8/cdb6b655-2e82-4179-b1e3-7592b031b1b8_2023-03-17 23:22:03.813455_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/cdb6b655-2e82-4179-b1e3-7592b031b1b8/cdb6b655-2e82-4179-b1e3-7592b031b1b8_2023-03-17 23:22:03.813455_4_compressed.png']


        },
    "a cowboy gunslinger walking the neon lit streets and alleys of a futuristic tokyo covered in a dense fog":
        {
            "images":
                ['https://s3.amazonaws.com/aibyu/user_images/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec_2023-03-19 17:22:43.794671_0.png', 'https://s3.amazonaws.com/aibyu/user_images/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec_2023-03-19 17:22:43.794671_1.png', 'https://s3.amazonaws.com/aibyu/user_images/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec_2023-03-19 17:22:43.794671_2.png', 'https://s3.amazonaws.com/aibyu/user_images/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec_2023-03-19 17:22:43.794671_3.png'],
            "compressed_images":
                ['https://s3.amazonaws.com/aibyu/user_images/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec_2023-03-19 17:22:43.794671_0_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec_2023-03-19 17:22:43.794671_1_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec_2023-03-19 17:22:43.794671_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec_2023-03-19 17:22:43.794671_3_compressed.png']
        },
    "3D render of a floating futuristic castle in a clear sky, digital art":
        {
            "images":
                ['https://s3.amazonaws.com/aibyu/user_images/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec_2023-03-19 17:26:18.221392_0.png', 'https://s3.amazonaws.com/aibyu/user_images/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec_2023-03-19 17:26:18.221392_1.png', 'https://s3.amazonaws.com/aibyu/user_images/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec_2023-03-19 17:26:18.221392_2.png', 'https://s3.amazonaws.com/aibyu/user_images/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec_2023-03-19 17:26:18.221392_3.png'],
            "compressed_images":
                ['https://s3.amazonaws.com/aibyu/user_images/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec_2023-03-19 17:26:18.221392_0_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec_2023-03-19 17:26:18.221392_1_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec_2023-03-19 17:26:18.221392_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec/5f9cd3a7-b0a7-4f70-8c17-f3c689e1a4ec_2023-03-19 17:26:18.221392_3_compressed.png']
        },
    "steampunk cat, octane render, hyper realistic":
        {
            "images":
                ['https://s3.amazonaws.com/aibyu/user_images/efff0142-b8d5-4286-a065-eca27030fc5d/efff0142-b8d5-4286-a065-eca27030fc5d_2023-03-19 17:28:46.609099_0.png', 'https://s3.amazonaws.com/aibyu/user_images/efff0142-b8d5-4286-a065-eca27030fc5d/efff0142-b8d5-4286-a065-eca27030fc5d_2023-03-19 17:28:46.609099_1.png', 'https://s3.amazonaws.com/aibyu/user_images/efff0142-b8d5-4286-a065-eca27030fc5d/efff0142-b8d5-4286-a065-eca27030fc5d_2023-03-19 17:28:46.609099_2.png', 'https://s3.amazonaws.com/aibyu/user_images/efff0142-b8d5-4286-a065-eca27030fc5d/efff0142-b8d5-4286-a065-eca27030fc5d_2023-03-19 17:28:46.609099_3.png'],
            "compressed_images":
                ['https://s3.amazonaws.com/aibyu/user_images/efff0142-b8d5-4286-a065-eca27030fc5d/efff0142-b8d5-4286-a065-eca27030fc5d_2023-03-19 17:28:46.609099_0_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/efff0142-b8d5-4286-a065-eca27030fc5d/efff0142-b8d5-4286-a065-eca27030fc5d_2023-03-19 17:28:46.609099_1_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/efff0142-b8d5-4286-a065-eca27030fc5d/efff0142-b8d5-4286-a065-eca27030fc5d_2023-03-19 17:28:46.609099_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/efff0142-b8d5-4286-a065-eca27030fc5d/efff0142-b8d5-4286-a065-eca27030fc5d_2023-03-19 17:28:46.609099_3_compressed.png']

        },
    "two people in love bathing in the sun on a beach in the south of france":
        {
            "images":
                ['https://s3.amazonaws.com/aibyu/user_images/23552516-2872-4cc3-ab69-9842c575e49f/23552516-2872-4cc3-ab69-9842c575e49f_2023-03-19 17:33:12.539531_0.png', 'https://s3.amazonaws.com/aibyu/user_images/23552516-2872-4cc3-ab69-9842c575e49f/23552516-2872-4cc3-ab69-9842c575e49f_2023-03-19 17:33:12.539531_1.png', 'https://s3.amazonaws.com/aibyu/user_images/23552516-2872-4cc3-ab69-9842c575e49f/23552516-2872-4cc3-ab69-9842c575e49f_2023-03-19 17:33:12.539531_2.png', 'https://s3.amazonaws.com/aibyu/user_images/23552516-2872-4cc3-ab69-9842c575e49f/23552516-2872-4cc3-ab69-9842c575e49f_2023-03-19 17:33:12.539531_3.png'],
            "compressed_images":
                ['https://s3.amazonaws.com/aibyu/user_images/23552516-2872-4cc3-ab69-9842c575e49f/23552516-2872-4cc3-ab69-9842c575e49f_2023-03-19 17:33:12.539531_0_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/23552516-2872-4cc3-ab69-9842c575e49f/23552516-2872-4cc3-ab69-9842c575e49f_2023-03-19 17:33:12.539531_1_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/23552516-2872-4cc3-ab69-9842c575e49f/23552516-2872-4cc3-ab69-9842c575e49f_2023-03-19 17:33:12.539531_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/23552516-2872-4cc3-ab69-9842c575e49f/23552516-2872-4cc3-ab69-9842c575e49f_2023-03-19 17:33:12.539531_3_compressed.png']

        },
    "astronaut lost in space in vector style":
        {
            "images":
                ['https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 15:57:28.703168_0.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 15:57:28.703168_1.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 15:57:28.703168_2.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 15:57:28.703168_3.png'],
            "compressed_images":
                ['https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 15:57:28.703168_0_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 15:57:28.703168_1_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 15:57:28.703168_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 15:57:28.703168_3_compressed.png']

        },
    "A closeup of an ancient African wizard holding a talisman and casting a spell at the beach in the starlight as digital art":
        {
            "images":
                ['https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 15:59:44.014642_0.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 15:59:44.014642_1.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 15:59:44.014642_2.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 15:59:44.014642_3.png'],
            "compressed_images":
                ['https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 15:59:44.014642_0_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 15:59:44.014642_1_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 15:59:44.014642_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 15:59:44.014642_3_compressed.png']
        },
    "the starry night from vincent van gogh featuring a developer working on a laptop":
        {
            "images":
                ['https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:05:35.002334_0.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:05:35.002334_1.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:05:35.002334_2.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:05:35.002334_3.png'],
            "compressed_images":
                ['https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:05:35.002334_0_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:05:35.002334_1_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:05:35.002334_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:05:35.002334_3_compressed.png']
        },
    "a 3d render of a web designer in a cool swedish office in stockholm":
        {
            "images":
                ['https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:06:33.161946_0.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:06:33.161946_1.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:06:33.161946_2.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:06:33.161946_3.png'],
            "compressed_images":
                ['https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:06:33.161946_0_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:06:33.161946_1_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:06:33.161946_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:06:33.161946_3_compressed.png']
        },
    "synth-wave NYC skyline with Statue of Liberty and a sunset, insane detail":
        {
            "images":
                ['https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:10:06.131800_0.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:10:06.131800_1.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:10:06.131800_2.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:10:06.131800_3.png'],
            "compressed_images":
                ['https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:10:06.131800_0_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:10:06.131800_1_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:10:06.131800_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:10:06.131800_3_compressed.png']
        },
    "3D render of a huge cute penguin on top of the little world, soft pink background, digital art":
        {
            "images":
                ['https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:11:14.557750_0.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:11:14.557750_1.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:11:14.557750_2.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:11:14.557750_3.png'],
            "compressed_images":
                ['https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:11:14.557750_0_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:11:14.557750_1_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:11:14.557750_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a9803981-c255-441e-aa4f-737907d5c266/a9803981-c255-441e-aa4f-737907d5c266_2023-04-22 16:11:14.557750_3_compressed.png']
        },
    "A man sitting in a brown table chair staring at the end of the universe without a care in the world, drawn digitally":
        {
            "images":
                ['https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:13:49.765071_0.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:13:49.765071_1.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:13:49.765071_2.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:13:49.765071_3.png'],
            "compressed_images":
                ['https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:13:49.765071_0_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:13:49.765071_1_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:13:49.765071_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:13:49.765071_3_compressed.png']
        },
    "two anthropomorphic dogs baking cookies":
        {
            "images":
                ['https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:23:44.331887_0.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:23:44.331887_1.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:23:44.331887_2.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:23:44.331887_3.png'],
            "compressed_images":
                ['https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:23:44.331887_0_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:23:44.331887_1_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:23:44.331887_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:23:44.331887_3_compressed.png']
        },
    "two beagles doing yoga, lots of warm colors, digital art":
        {
            "images":
                ['https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:24:57.754251_0.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:24:57.754251_1.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:24:57.754251_2.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:24:57.754251_3.png'],
            "compressed_images":
                ['https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:24:57.754251_0_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:24:57.754251_1_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:24:57.754251_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:24:57.754251_3_compressed.png']
        },
    "a teenage girl of afghani descent with striking rainbow eyes stares at the camera with a deep read head scarf. kodachrome film":
        {
            "images":
                ['https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:26:47.422348_0.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:26:47.422348_1.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:26:47.422348_2.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:26:47.422348_3.png'],
            "compressed_images":
                ['https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:26:47.422348_0_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:26:47.422348_1_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:26:47.422348_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:26:47.422348_3_compressed.png']
        },
    "an astronaut riding a horse in a photorealistic style":
        {
            "images":
                ['https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 16:31:29.030505_0.png', 'https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 16:31:29.030505_1.png', 'https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 16:31:29.030505_2.png', 'https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 16:31:29.030505_3.png'],
            "compressed_images":
                ['https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 16:31:29.030505_0_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 16:31:29.030505_1_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 16:31:29.030505_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 16:31:29.030505_3_compressed.png']

        },
    "a boy and his father riding a bicycle down a steep hill on a sunny day, polaroid":
        {
            "images":
                ['https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 16:36:27.835989_0.png', 'https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 16:36:27.835989_1.png', 'https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 16:36:27.835989_2.png', 'https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 16:36:27.835989_3.png'],
            "compressed_images":
                ['https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 16:36:27.835989_0_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 16:36:27.835989_1_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 16:36:27.835989_2_compressed.png', 'https://s3.amazonaws.com/aibyu/user_images/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae/a98078e3-15fd-4ef8-8c1e-5de0ee5cecae_2023-04-22 16:36:27.835989_3_compressed.png']
        }

};


const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <ErrorPage/>,
    },
    {
        path: "login",
        element: <Login/>,
        errorElement: <ErrorPage/>,
    },
    {
        path: "app",
        element: <App/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "prompt-engineering",
        element: <App/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "generative-ai",
        element: <App/>,
        errorElement: <ErrorPage/>
    }
]);


ReactDOMClient.createRoot(document.getElementById("root")).render(
    <Provider store={store}>
            <RouterProvider
                router={router}
            />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
