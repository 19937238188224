import '../pages/Creation/Creation.css';
import '../Project.css'
/* global ClipboardItem */
import React, {useCallback, useEffect, useState} from "react";
import {memo} from "react";
// Medium
import random from "../assets/Images/lightbulb.svg";
import search from "../assets/Images/search.png"
import information from "../assets/Images/92d7b7d355967300bab1427f0bff968c.svg"
import chalk from "../assets/Images/medium/chalk.png"
import glass from "../assets/Images/medium/glass.png"
import oil from "../assets/Images/medium/oil.png"
import picture from "../assets/Images/medium/picture.png"
import sketch from "../assets/Images/medium/sketch.png"
import spray from "../assets/Images/medium/spray.png"
import watercolor from "../assets/Images/medium/watercolor.png"

//Artists
import dali2 from "../assets/Images/artists/dali.png"
import picasso from "../assets/Images/artists/picasso.png"
import pixar from "../assets/Images/artists/pixar.png"
import southpark from "../assets/Images/artists/southpark.png"
import unreal from "../assets/Images/artists/unreal.png"
import vangogh from "../assets/Images/artists/vangogh.png"
import warhol from "../assets/Images/artists/warhol.png"

//Keywords
import ambient from "../assets/Images/keywords/ambient.png"
import blackandwhite from "../assets/Images/keywords/blackandwhite.png"
import closeup from "../assets/Images/keywords/closeup.png"
import highresolution from "../assets/Images/keywords/highresolution.png"
import insanedetail from "../assets/Images/keywords/insanedetail.png"
import simple from "../assets/Images/keywords/simple.png"
import ultrarealistic from "../assets/Images/keywords/ultrarealistic.png"

//Style
import abstract from "../assets/Images/style/abstract.png"
import cartoon from "../assets/Images/style/cartoon.png"
import digitalart from "../assets/Images/style/digitalart.png"
import oneline from "../assets/Images/style/oneline.png"
import pixelart from "../assets/Images/style/pixelart.png"
import psychedellic from "../assets/Images/style/psychedellic.png"
import retro from "../assets/Images/style/retro.png"
import steampunk from "../assets/Images/style/steampunk.png"
import synthwave from "../assets/Images/style/synthwave.png"


import {base_url, prompt_dict} from "../index";
import 'bootstrap/dist/css/bootstrap.css';
import {ProgressBarComponent} from "./HelperComponents";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ClickableImage from "./ClickableImage";
import Tags from "./Tag";
import {useNavigate} from "react-router-dom";
import {ImageGrid} from "./ImageGrid";


const promptOptions = {
    style: {
        items: ["Salvador Dali", "Andy Warhol", "Pablo Picasso", "Van Gogh", "Pixar", "South Park", "Unreal Engine", "Photograph", "Oil Painting", "Watercolor", "Chalk", "Spray Paint", "Sketch", "Steampunk", "Abstract", "Synthwave", "Retro", "Psychedelic", "One Line", "Cartoon", "Pixel Art", "Digital Art", "Ultra Realistic", "Insane Detail", "High Resolution", "Close Up", "Ambient Light", "Simple", "Black and White"],
        images: [dali2, warhol, picasso, vangogh, pixar, southpark, unreal, picture, oil, watercolor, chalk, spray, sketch, steampunk, abstract, synthwave, retro, psychedellic, oneline, cartoon, pixelart, digitalart, ultrarealistic, insanedetail, highresolution, closeup, ambient, simple, blackandwhite],
    },
};


const SubmissionComponent = ({onOutputChange, randomElement1, randomElement2, product_titles_dict}) => {

    console.log("Submission render")
    console.log(product_titles_dict)
    let navigate = useNavigate();


    let user_email = JSON.parse(localStorage.getItem('email'));

    const [submitting, setSubmitting] = useState(false);
    const [design_desc, setDesignDesc] = useState("");
    const [isDisabled, setDisabled] = useState(false);
    const [seenElements, setSeenElements] = useState(new Set());
    const [others, setOthers] = useState([])
    const [imageBorders, setImageBorders] = useState(Array.from({ length: promptOptions.style.items.length }, () => false))

    //Make sure user has not exceeded number of image generations
    async function checkExceeded() {
        let res = await fetch(`${base_url}/creation/count/${user_email}`);
        let count = await res.json();
        count = count['num_calls'];
        if (count >= 20) {
            await setDisabled(true);
        } else {
            await setDisabled(false);
        }
    }

    useEffect(() => {
        console.log('Submission mounted');
        checkExceeded()
        return () => {
            console.log('Submission unmounted');
        }
    }, []);
    // 
    async function showPresetDesign(prompt_in) {
        setSubmitting(true);
        try {
            localStorage.setItem('currentImages', JSON.stringify(prompt_dict[prompt_in]));
            localStorage.setItem('design_desc', JSON.stringify(prompt_in));
            onOutputChange(prompt_dict[prompt_in]);
            setSubmitting(false);
            console.log("SCROLLING")
        } catch (err) {
            console.log(err);
            setSubmitting(false);
        }
    }


    let submitDesign = async (e) => {
        setDisabled(true);
        setSubmitting(true);
        const promptElements = [design_desc, ...others];

        const prompt = promptElements.filter(el => el !== "").join(", ");
        console.log("SUBBMITTING", product_titles_dict)
        try {
            let res = await fetch(`${base_url}/creation/create`, {
                method: 'POST', headers: {
                    'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'
                }, body: JSON.stringify({
                    'prompt': prompt, 'email': user_email, 'products': product_titles_dict
                })
            });
            let resJson = await res.json();
            console.log("ALL PRODUCTS WO", resJson["all_products"])
            if (res.status === 200) {
                let output = resJson.images;
                localStorage.setItem('currentImages', JSON.stringify(resJson));
                localStorage.setItem('design_desc', JSON.stringify(prompt));
                onOutputChange(resJson);
            } else {
                window.alert(resJson.error.message)
            }
            checkExceeded();
            setSubmitting(false);
            // setDisabled(false);
        } catch (err) {
            console.log(err);
            setSubmitting(false);
        }
    };

    const handleSubmit = e => {
        if (isDisabled === true) {
            window.alert("User has exceeded allowed number of calls within 24 hours.")
            return
        }
        submitDesign();
    }

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.key === 'Enter') {
            e.preventDefault();
            if (isDisabled === true) {
                window.alert("User has exceeded allowed number of calls within 24 hours.")
                return
            }
            submitDesign();
        }
    };

    function clear_inputs() {
        setDesignDesc("")
        setOthers([])
        setImageBorders(Array.from({ length: promptOptions.style.items.length }, () => false))
    }

    function getRandomElement() {
        let arr = Object.keys(prompt_dict);
        if (seenElements.size === arr.length) {
            // Reset the seenElements set when all elements have been seen
            seenElements.clear();
            setSeenElements(seenElements);
        }

        let randomElement;
        do {
            randomElement = arr[Math.floor(Math.random() * arr.length)];
        } while (seenElements.has(randomElement));

        seenElements.add(randomElement);
        setSeenElements(seenElements)
        return randomElement;
    }

    let arr = Object.keys(prompt_dict);
    let randomElement1capt = arr[randomElement1]
    let randomElement2capt = arr[randomElement2]

    const ActionButton = ({id, tooltipText, handleClick, buttonImage, buttonText, style}) => (
        <div id={id} style={style} onClick={handleClick}>
            <OverlayTrigger placement="top" overlay={<Tooltip>{tooltipText}</Tooltip>}>
                <button className="random random2" onClick={handleClick}>
                    <img className="random3" src={buttonImage} alt='random'/>
                </button>
                </OverlayTrigger>
            <div className="blah">{buttonText}</div>
        </div>);
    const StyleItems = ({items, images, handleClick}) => {
        console.log("Style items rendered")
        useEffect(() => {
            console.log('Style items mounted');
            return () => {
                console.log('Style items unmounted');
            };
        }, []);
        return (<div className="styleItems">
            {items.map((title, i) => (<div className="innerItems" style={{color: 'white'}}>
                <div style={{position: 'relative', width: '6vw'}} onClick={() => {
                    handleClick(title, i)
                }}>
                    <ClickableImage key={i} src={images[i]} alt={i} title={title} onImageClick={() => handleClick(title, i)}/>
                </div>
            </div>))}
            <div className="scroll-indicator">&#x2192;</div>
        </div>);
    };
    const handleItemClick = useCallback((title) => {
        if (others.includes(title)) {
            let updatedOthers = others.filter(function (letter) {
                return letter !== title;
            });
            setOthers(updatedOthers);
        } else {
            setOthers([...others, title]);
        }
    }, [others]);

    const styleConfigurations = [{
        items: promptOptions.style.items, images: promptOptions.style.images, type: "style"
    },];
    return (<div className="SubmissionWrapper">
        {/*<ImageGrid*/}
        {/*        currentImages={currentImages}*/}
        {/*        product_titles_dict={product_titles_dict}*/}
        {/*        changeTab={changeTab}*/}
        {/*    />*/}
        <div>
            <div>
                <div className="containert">
                    <OverlayTrigger placement="top" overlay={<Tooltip>
                        Click to clear all inputs.
                    </Tooltip>}>
                        <button className="close-btn" onClick={clear_inputs}>
                            &times;
                        </button>
                    </OverlayTrigger>
                    <textarea
                        autoComplete="off"
                        name="design_description"
                        id="design_description_inner"
                        className="touching-div"
                        value={design_desc}
                        onChange={(e) => setDesignDesc(e.target.value)}
                        placeholder="Write description to create image"
                        onKeyPress={handleKeypress}
                    />
                    {others.length === 0 ? null : <Tags tags={others}/>}

                </div>
                <div className="SubmissionTabs">
                    <ActionButton id="design_description2" tooltipText="Click to create image."
                                  handleClick={handleSubmit} buttonImage={search} buttonText="Create"
                                  style={{'border-right': 'solid', 'border-width': '1px'}}/>
                    <ActionButton id="design_description2" tooltipText="Click to try random image prompt."
                                  handleClick={async (e) => {
                                      let randomElement = getRandomElement();
                                      setDesignDesc(randomElement);
                                      showPresetDesign(randomElement);
                                  }} buttonImage={random} buttonText="Ideas"
                                  style={{'border-right': 'solid', 'border-width': '1px'}}/>
                    {/*<ActionButton id="design_description2" tooltipText="Click to get more info on AI image generation."*/}
                    {/*              handleClick={async (e) => {*/}
                    {/*                  window.open('https://dallery.gallery/wp-content/uploads/2022/07/The-DALL%C2%B7E-2-prompt-book-v1.02.pdf', '_blank');*/}
                    {/*              }} buttonImage={information} buttonText="Prompt"*/}
                    {/*              style={{}}/>*/}
                </div>
            </div>
        </div>
        {submitting ? <div>
            <ProgressBarComponent onSubmit={submitDesign}/>
        </div> : <div>
            {/*<div className='examples'>*/}
            {/*    <button className='example to_skip' onClick={async (e) => {*/}
            {/*        setDesignDesc(randomElement1capt);*/}
            {/*        showPresetDesign(randomElement1capt)*/}
            {/*    }}>*/}
            {/*        "{randomElement1capt}"*/}
            {/*    </button>*/}
            {/*    <button className='example to_skip' onClick={async (e) => {*/}
            {/*        setDesignDesc(randomElement2capt);*/}
            {/*        showPresetDesign(randomElement2capt)*/}
            {/*    }}>*/}
            {/*        "{randomElement2capt}"*/}
            {/*    </button>*/}
            {/*</div>*/}
            <div className={"styleWrapper"}>
                <div className="Tags">Tags: <i className={"ita"}>artists</i><i className={"ita"}>medium</i><i className={"ita"}>style</i></div>
                {styleConfigurations.map((config, i) => (
                    <div className="styleItems">
                        {config.items.map((title, i) => (<div className="innerItems" style={{color: 'white'}}>
                                <div style={{position: 'relative', width: '6vw'}} onClick={() => {handleItemClick(title)}}>
                                    <ClickableImage key={i} src={config.images[i]} title={title} alt={i} state={imageBorders[i]}/>
                                </div>
                            </div>))}
                        <div className="scroll-indicator">&#x2192;</div>
                    </div>
                ))}
            </div>
        </div>}
    </div>)
}

// export default SubmissionComponent
export default memo(SubmissionComponent, (prevProps, nextProps) => {
    if (prevProps.product_titles_dict != nextProps.product_titles_dict) {
        return false
    }
    return true
});