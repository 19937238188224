import React, {useState} from 'react';
import './HamburgerMenu.css';
import About from "../pages/About/About";
import ImageGallery from "../pages/ImageGallery/ImageGallery";
import Creation from "../pages/Creation/Creation";
import Catalog from "../pages/Catalog/Catalog";
import ham from '../assets/Images/Screenshot 2023-05-05 at 4.05.05 PM.png'
import { memo } from "react";
const HamburgerMenu = ({changeTab}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="hamburger-menu">
            <button className="hamburger" onClick={toggleMenu}>
                <img src={ham} style={{'width': '5vw'}}/>
            </button>
            {isOpen && (
                <ul className="menu">
                    <li><a href="/" onClick={(e) => {
                        e.preventDefault();
                        changeTab(<Creation changeTab={changeTab}/>, 0);
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'instant'
                        });
                        setIsOpen(!isOpen);
                    }}>Studio</a></li>
                    <li><a href="/"
                           onClick={(e) => {
                               e.preventDefault();
                               changeTab(<ImageGallery/>, 1);
                               window.scrollTo({
                                   top: 0,
                                   left: 0,
                                   behavior: 'instant'
                               });
                               setIsOpen(!isOpen);
                           }}>Gallery</a></li>
                    <li><a href="/" onClick={(e) => {
                        e.preventDefault();
                        changeTab(<Catalog/>, 2);
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: 'instant'
                        });
                        setIsOpen(!isOpen);
                    }}>Catalog</a></li>
                    <li><a
                        onClick={(e) => {
                            e.preventDefault();
                            changeTab(<About changeTab={changeTab} />, 3);
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: 'instant'
                            });
                            setIsOpen(!isOpen);
                        }}
                        href="/">About</a></li>
                </ul>
                // <SiteMap changeTab={changeTab} siteMapRef={siteMapRef} classname={"sitemap"}/>
            )}
        </div>
    );
};

export default memo(HamburgerMenu);