import './Login.css';
import '../../Project.css'
import React, {useEffect, useState} from 'react'
import {useNavigate} from "react-router-dom";
import axios from "axios"
import 'bootstrap/dist/css/bootstrap.css';
import side from "../../assets/Images/hallucinogenic-new-york-city-skyline-futuristic-with-lots-of-water.jpeg";
import {base_url} from "../../index";
import logo from "../../assets/Images/Wordpress Transparent.png"

function Login() {
    console.log("LOGIN")

    let navigate = useNavigate();

    const [email, setEmail] = useState("");

    // Clear local storage everytime someone comes to login page
    useEffect(() => {
        localStorage.setItem('currenttab', 0)
        localStorage.setItem('loggedin', JSON.stringify('t'))
    }, []);


    // Create a new user id
    function generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if (d > 0) {//Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    // Handle login when user does not have an account
    let handleLoginNoAccount = async (e) => {
        let use_email = generateUUID();
        localStorage.setItem('email', JSON.stringify(use_email));
        const iteneraryBody = {};
        axios.post(`${base_url}/user/${use_email}`, iteneraryBody);
        navigate('/');
    };

    // Check if email exists in db, check if password matches
    let handleLogin = async (e) => {
        let use_email = email;
        if (use_email === "") {
            window.alert("Must set email to login.")
            return
        } else {
            const resp = await fetch(`${base_url}/user/${use_email}`);
            let exists = await resp.json()
            exists = exists['exists']
            console.log("EXISTS", exists)
            if (exists === true) {
                localStorage.setItem('email', JSON.stringify(use_email));
            } else {
                localStorage.setItem('email', JSON.stringify(use_email));
                const iteneraryBody = {};
                let r = axios.post(`${base_url}/user/${use_email}`, iteneraryBody);
            }
            localStorage.setItem('loggedin', JSON.stringify('f'));
        }
        navigate('/');
    };

    // Submit the login request if the user presses enter
    const handleKeypress = e => {
        if (e.key === 'Enter') {
            handleLogin();
        }
    };

    return (
        <div className="LoginPage">
            <div className="LoginContent">
                <img className='LoginImage' src={side} alt='LoginImage'/>
                <div className='LoginCard' border="dark">
                    <img src={logo} style={{"width": "15vmin", "margin": "auto"}} alt={"LOGO"}/>
                    <span id="LogoName">AIBYU</span>
                    <div className="LoginInfo">Please sign-in to start your adventure</div>
                    <div>
                        <input
                            type="text"
                            autoComplete="off"
                            className="LoginInput"
                            name="design_description"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            onKeyPress={handleKeypress}
                            placeholder='Username'
                        />
                    </div>
                    <button className='LoginButton' onClick={handleLogin}>Login</button>
                    <br/>
                    <div>
                        No account?
                        <div id='LoginSignup' onClick={handleLoginNoAccount}>Continue without login</div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Login;
