import React, {memo, useEffect, useState} from "react";
import axios from "axios";
import {base_url} from "../index";
import Carousel from "react-bootstrap/Carousel";
import Card from "react-bootstrap/Card";
import close from "../assets/Images/close.svg";
import {ProgressBarComponent2} from "./HelperComponents";
import LoadingOverlay from 'react-loading-overlay'; // npm i -S react-loading-overlay --legacy-peer-deps

const TopImage = ({
                             product_titles_dict,
                             selected_image, noncompressed_image,
                             is_top,
                             setIsTop,
                             top,
                             chosenProduct,
                             setChosenProduct,
                         }) => {

    const [productMaking, setProductMaking] = useState(false)
    const [postedImage, setPostedImage] = useState(top);
    const [mockUps, setMockUps] = useState(null)
    const [url, setUrl] = useState("")
    const [mockUpsLoading, setMockUpsLoading] = useState(false)
    const [productLoading, setProductLoading] = useState(false)
    const [loadingText, setLoadingText] = useState(null)

    async function getAll() {
        let email = JSON.parse(localStorage.getItem('email'));
        let design_descript = JSON.parse(localStorage.getItem('design_desc'));
        let title = product_titles_dict;
        const body2 = {
            image: selected_image, email: email
        };
        let t = await axios.post(`${base_url}/printify/products/create_img`, body2)
        t = t['data']
        const body3 = {
            title: design_descript, image_id: t['id'], products: title
        };
        let t2 = await axios.post(`${base_url}/printify/products/create_all`, body3);
        console.log("NEW", t2)

    }

    async function getProductMockups(name) {
        setMockUpsLoading(true)
        //TODO cache this, also only load image once
        let email = JSON.parse(localStorage.getItem('email'));
        let design_descript = JSON.parse(localStorage.getItem('design_desc'));
        let title = product_titles_dict[name];
        const body2 = {
            image: selected_image, email: email
        };
        let t = await axios.post(`${base_url}/printify/products/create_img`, body2)
        setPostedImage(t)
        t = t['data']
        const body3 = {
            title: design_descript, image_id: t['id'], product: title
        };
        let t2 = await axios.post(`${base_url}/printify/products/create_one`, body3);
        await setMockUps(t2['data']['images'])
        await setChosenProduct(1);
        setMockUpsLoading(false)

    }

    function checkBoxActions(event) {
        let checkboxes = document.getElementsByClassName("ProductCheckbox");
        let on = event.target.checked;
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].name !== event.target.name) {
                checkboxes[i].checked = false
            } else if (on) {
                setMockUpsLoading(true)
                getAll()
                getProductMockups(checkboxes[i].name)
            } else {

                setMockUpsLoading(false)

                setChosenProduct(null);
            }
        }
    }

    function openWindow(url) {
        window.open(url, '_blank');
    }

    async function postToPrintify() {
        console.log("POSTING PRODUCT")
        setLoadingText("Creating product...")
        setProductLoading(true)
        let checkboxes = document.getElementsByClassName("ProductCheckbox");
        let email = JSON.parse(localStorage.getItem('email'));
        let design_descript = JSON.parse(localStorage.getItem('design_desc'));
        let product_id = ""
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked === true) {
                let title = product_titles_dict[checkboxes[i].name];
                const body2 = {
                    image: noncompressed_image, email: email
                };
                let t = await axios.post(`${base_url}/printify/products/create_img`, body2)
                t = t['data']
                const body = {
                    title: design_descript, image: noncompressed_image, product: title, email: email, image_id: t['id']
                };
                let result = await axios.post(`${base_url}/printify/products`, body);
                product_id = result['data']['product_id']
            }
        }
        let r = await fetch(`${base_url}/printify3`, {
            method: 'POST', headers: {
                'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'
            }, body: JSON.stringify({
                'product_id': product_id,
            })
        });
        let r2 = await r.json()
        setProductMaking(false);
        setProductLoading(false)
        setLoadingText(null)
        setUrl(r2)
        openWindow(r2);
    }

    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    // TODO: should split these out into components, standardize css naming
    if (is_top) {
        return (<div className="ShowingProduct">
            <div className="s">
                <div className='DesignCardL'>
                    <LoadingOverlay
                        active={mockUpsLoading || productLoading}

                        spinner={true}
                        text={loadingText ? <div>
                            {loadingText}
                            {productMaking ? <ProgressBarComponent2/> : null}
                        </div>: "Loading product..."}
                    >
                        {chosenProduct === null ? <div className='DesignCardL'>
                            <img className='DesignImage' src={selected_image} alt={noncompressed_image}/>
                        </div> : <div className='DesignCardL'>
                            <div className={"DesignImage"}>
                                <Carousel
                                    activeIndex={index}
                                    variant='dark'
                                    slide={false}
                                    onSelect={handleSelect}
                                    interval={null}
                                    className='Left'
                                >
                                    {mockUps.map(image => <Carousel.Item>
                                        <Card.Img variant="top" src={`data:image/jpeg;base64,${image.src}`}/>
                                    </Carousel.Item>)}
                                </Carousel>
                            </div>
                        </div>}
                    </LoadingOverlay>
                </div>
                <div className='DesignCardR'>
                    <div className='DesignCardInnerR'>
                        <img className="CloseButton" src={close}
                             onClick={event => {
                                 setIsTop(false)
                                 setChosenProduct(null)
                                 setUrl("")
                             }}
                             alt={"CloseButton"}
                        />
                        <div className="DesignInfo">Select Product</div>
                        <div className="DesignButtons">
                            {Object.values(product_titles_dict).map((title) => (<div className="ProductCheckboxGroup">
                                <input className="ProductCheckbox" type="checkbox" name={title.title}
                                       onClick={event => {
                                           checkBoxActions(event);
                                       }}/>
                                <div className="ProductTitle">{title.title}</div>
                            </div>))}
                        </div>
                        <div>
                            <button className='LoginButton DesignButton CreationButton'
                                    onClick={event => {
                                        // setIsTop(false);
                                        let checkboxes = document.getElementsByClassName("ProductCheckbox");
                                        let isChecked = Array.from(checkboxes).some(checkbox => checkbox.checked);
                                        if (isChecked === true) {
                                            setProductMaking(true);
                                            postToPrintify();
                                        } else {
                                            window.alert("Must select a product before purchasing.")
                                        }
                                    }}
                            >
                                Buy Now
                            </button>
                            <div style={{"margin-top": '1vh'}}>{url !== "" ?
                                <a href={url} target='_blank'>Checkout link</a> : null}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
    }
}

export default memo(TopImage)