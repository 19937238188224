import '../pages/Creation/Creation.css';
import '../Project.css'
/* global ClipboardItem */
/* global ClipboardItem */
import React, {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.css';
import {FaCopy, FaDownload} from 'react-icons/fa';
import ProgressBar from "react-bootstrap/ProgressBar";
// Medium

//Artists

//Keywords

//Style
export const downloadImage = (url) => {
    fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'image_name.jpg';
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch((error) => console.error('Error downloading image:', error));
};

export const CopyImageButton = ({imageUrl, fileName}) => {
    return (<div className="copy-image-button">
        <a onClick={async (e) => {
            try {
                const response = await fetch(imageUrl);
                const blob = await response.blob();
                const data = [new ClipboardItem({[blob.type]: blob})];
                await navigator.clipboard.write(data);
                alert('Image copied to clipboard!');
            } catch (err) {
                console.error(err);
                alert('Failed to copy image. Please try again.');
            }
        }}>
            <button>
                <FaCopy/>
            </button>
        </a>
    </div>);
};

export const DownloadImageButton = ({imageUrl, fileName}) => {
    return (<div className="download-image-button">
        <a onClick={(e) => {
            try {
                e.preventDefault();
                downloadImage(imageUrl);
            } catch (err) {
                console.error(err);
                alert('Failed to download image. Please try again.');
            }
        }}>
            <button>
                <FaDownload/>
            </button>
        </a>
    </div>);
};

export class Product {
    constructor(data, variants) {
        this.title = data.title;
        this.images = data.images;
        this.id = data.id;
        this.variants = variants;
        this.description = data.description
    }
}


export const ProgressBarComponent = React.memo(({onSubmit}) => {
    const [progress, setProgress] = useState(0);
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        if (!isUpdating) return;

        const intervalId = setInterval(() => {
            setProgress((prevProgress) => prevProgress + 100 / 240);
        }, 50); // Update progress every 1 second

        setTimeout(() => {
            setIsUpdating(false);
            clearInterval(intervalId);
        }, 12000); // Stop updating progress after 12 seconds

        return () => clearInterval(intervalId);
    }, [isUpdating]);

    useEffect(() => {
        setIsUpdating(true);
    }, [onSubmit]);

    return (<div>
        <ProgressBar style={{'width': '50%', 'margin': 'auto', 'margin-top': '10vmin'}} animated now={progress}/>
    </div>);
});

export const ProgressBarComponent2 = React.memo(() => {
    const [progress, setProgress] = useState(0);
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        if (!isUpdating) return;

        const intervalId = setInterval(() => {
            setProgress((prevProgress) => prevProgress + 100 / 480);
        }, 50); // Update progress every 1 second

        setTimeout(() => {
            setIsUpdating(false);
            clearInterval(intervalId);
        }, 24000); // Stop updating progress after 12 seconds

        return () => clearInterval(intervalId);
    }, [isUpdating]);

    useEffect(() => {
        setIsUpdating(true);
    }, []);

    return (<div>
        {/*<div style={{'font-size': '2vmin', 'margin-top': '1vh'}}>Product is being created</div>*/}
        <ProgressBar style={{'width': '50%', 'margin': 'auto', 'margin-bottom': '2vmin', 'margin-top': '2vmin'}}
                     animated now={progress}/>
    </div>);
});