import './ImageGallery.css';
import '../../Project.css'
import React, {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {base_url} from "../../index";
import logo from "../../assets/Images/loading-loader.svg";
import close from "../../assets/Images/close.svg";
import {Product} from "../../components/HelperComponents";
import {Button} from "react-bootstrap";
import {monthMapping} from "../../utils/helpers";
import LazyLoad from 'react-lazyload';
import TopImage from "../../components/TopImage";

function Sortbar({num_products, marketplace, setMarketPlace, get_data, sort, setSort}) {
    console.log("SORTBAR")
    return (<div className="CatalogSortbar">
        <div className="InsideSortbarLeft">
            <div>Community</div>
        </div>
        <div className="InsideSortbarRight">
            <div className="SortbarGroup">
                <div className="CatalogSortbarItem">Images:</div>
                <DropdownButton className="CardBasic" title={marketplace} variant='secondary'>
                    <Dropdown.Item onClick={event => {
                        setMarketPlace("Mine");
                        get_data("Mine");
                    }}>Mine</Dropdown.Item>
                    <Dropdown.Item onClick={event => {
                        setMarketPlace("Community");
                        get_data("All");
                    }}>Community</Dropdown.Item>
                </DropdownButton>
            </div>
            <div className="SortbarGroup">
                <div className="CatalogSortbarItem">Sort by:</div>
                <DropdownButton className="CardBasic" title={sort} variant='secondary'>
                    <Dropdown.Item onClick={event => {
                        setSort("New to Old");
                    }}>New to Old</Dropdown.Item>
                    <Dropdown.Item onClick={event => {
                        setSort("Old to New");
                    }}>Old to new</Dropdown.Item>
                </DropdownButton>
            </div>
        </div>
    </div>)
}

function ImageGallery({delay}) {
    console.log("CATALOG")

    const [num_products, setNumProducts] = useState(0);
    const [allImages, setAllImages] = useState(null);
    const [images, setImages] = useState([]);
    const [sort, setSort] = useState("New to Old");
    const [marketplace, setMarketPlace] = useState("Community");
    const [updateFormat, setUpdateFormat] = useState(0);
    const [imgproducts, setimgproducts] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [recordsPerPage] = useState(6);
    const [index, setIndex] = useState(0);
    const [chosenProduct, setChosenProduct] = useState(null)
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (delay === true) {
            setTimeout(() => {
                get_data("All");
            }, 5500);
        } else {
            get_data("All");
        }
    }, []);

    async function get_data(marketplace) {
        console.log("GETTING DATA")
        let email = JSON.parse(localStorage.getItem('email'));
        let r = null
        if (marketplace === "Mine") {
            r = await fetch(`${base_url}/user_images/${email}`);
        } else {
            r = await fetch(`${base_url}/all_images`);
        }
        let allimages = await r.json();

        setAllImages(allimages);
        setImages(allimages);
        setUpdateFormat(updateFormat + 1);
    }

    function format_data() {
        if (allImages === null) {
            return
        }
        let sortedImages = allImages;
        setNumProducts(sortedImages.length);
        sortedImages = [...sortedImages].sort((a, b) => {
            const [weekday, day, month, year, time, timezone] = a.call_time.split(" ");
            const [weekdayb, dayb, monthb, yearb, timeb, timezoneb] = b.call_time.split(" ");

            const safariFriendlyDateStringA = `${year}-${monthMapping[month]}-${day}T${time}`;
            const safariFriendlyDateStringB = `${yearb}-${monthMapping[monthb]}-${dayb}T${timeb}`;
            if (sort === "Old to New") {
                return new Date(safariFriendlyDateStringA) - new Date(safariFriendlyDateStringB)
            } else {
                return new Date(safariFriendlyDateStringB) - new Date(safariFriendlyDateStringA)
            }
        });
        setImages(sortedImages);
        setCurrentPage(0);
    }


    function ShopifyProductBlock() {

        const [is_top, setIsTop] = useState(false);
        const [selected_image, setImage] = useState("none");
        const [noncompressed_image, setNonCompressedImage] = useState("none");
        const [product_titles_dict, setProductTitlesDict] = useState([]);
        const [isHovered, setIsHovered] = useState(false);

        const createButtonHandler = (event, src, alt) => {
            setIsHovered(false);
            onImageSelect(event, src, alt);
        };

        // Fetch Printify products on component mount
        useEffect(() => {
            getPrintifyProducts();
        }, []);

        // Fetch products from Printify API and create a dictionary
        async function getPrintifyProducts() {
            const resp = await fetch(`${base_url}/printify/products`);
            let apiarr = await resp.json();
            let product_dict = {};
            for (let i = 0; i < apiarr.length; i++) {
                let prod = new Product(apiarr[i][0], apiarr[i][1]);
                product_dict[prod.title] = prod;
            }
            setProductTitlesDict(product_dict);
        }

        useEffect(() => {
            const handleEsc = (event) => {
                if (event.keyCode === 27) {
                    setIsTop(false)
                }
            };
            window.addEventListener('keydown', handleEsc);
            return () => {
                window.removeEventListener('keydown', handleEsc);
            };
        }, []);

        useEffect(() => {
            format_data();
        }, [sort, updateFormat]);

        function nextPage() {
            let max_page = Math.floor(num_products / recordsPerPage);
            if (currentPage !== max_page) {
                setCurrentPage(currentPage + 1);
                window.scrollTo(0, 0);
            }
        }

        function previousPage() {
            if (currentPage !== 0) {
                setCurrentPage(currentPage - 1)
                window.scrollTo(0, 0);
            }
        }

        const onImageSelect = (event, src, alt) => {
            setImage(src);
            setNonCompressedImage(alt)
            setIsTop(true);
        };

        function checkBoxActions(event) {
            let checkboxes = document.getElementsByClassName("ProductCheckbox");
            let on = event.target.checked;
            for (let i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].name !== event.target.name) {
                    checkboxes[i].checked = false
                } else {
                    if (on) {
                        setChosenProduct(i);
                    } else {
                        setChosenProduct(null)
                    }
                }
            }
        }
        let imggrid = null;
        if (images.length > 0) {
            //.slice(currentPage * recordsPerPage, (currentPage + 1) * recordsPerPage).
            imggrid = images.map((product, productIndex) => {
                productIndex = productIndex + currentPage * recordsPerPage;
                return (<div style={{'text-align': 'center'}}>
                    <div className="catlog_image-grid_creation2">
                        {JSON.parse(product['compressed_images']).map((src, i) => (
                            <LazyLoad key={i} placeholder={<div>Loading...</div>} offset={100}>
                            <div className="image-container" key={i}>
                                <img
                                    className="card_catalog_creation"
                                    src={src}
                                    loading="lazy"
                                    onClick={(event) => onImageSelect(event, src, JSON.parse(product['images'])[i])}
                                    alt={JSON.parse(product['images'])[i]}
                                    key={i}
                                />
                                <div
                                    className="overlay" onMouseEnter={() => setIsHovered(true)}
                                    onMouseLeave={() => setIsHovered(false)}
                                >
                                    {isHovered &&
                                        (
                                            <Button
                                                className="create-button"
                                                onClick={(event) => createButtonHandler(event, src, JSON.parse(product['images'])[i])}
                                                key={i}
                                            >
                                                Create Products
                                            </Button>
                                        )
                                    }
                                </div>
                            </div></LazyLoad>))}
                    </div>
                </div>)
            })
        }
        if (allImages !== null) {
            return (<div>
                <TopImage
                    product_titles_dict={product_titles_dict}
                    selected_image={selected_image}
                    noncompressed_image={noncompressed_image}
                    is_top={is_top}
                    setIsTop={setIsTop}
                    top="none"
                    chosenProduct={chosenProduct}
                    setChosenProduct={setChosenProduct}
                />
                <div>
                    {imggrid}
                </div>
            </div>)
        } else {
            return (<div>
                <img src={logo} className="SpinningWheel SW2" alt="SpinningWheel"/>
            </div>)
        }
    }

    return (<div>
        <Sortbar
            num_products={num_products}
            marketplace={marketplace}
            setMarketPlace={setMarketPlace}
            get_data={get_data}
            sort={sort}
            setSort={setSort}
        />
        <div>
            {ShopifyProductBlock()}
        </div>
    </div>)
}


export default ImageGallery;
