import React from 'react';

const Tags = ({ tags }) => {
  return (
    <div className={"tags"}>
      <div style={{ marginLeft: '2vh', display: 'flex', justifyContent: 'center', 'flex-direction': 'column'}}>tags:</div>
      {tags.map((name, i) => (
        name !== '' && (
          <div key={i} className="tag">
            {name}
          </div>
        )
      ))}
    </div>
  );
};

export default Tags;