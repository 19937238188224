import React, {memo, useEffect, useMemo, useState} from "react";
import '../pages/Creation/Creation.css';
import '../Project.css'
import 'bootstrap/dist/css/bootstrap.css';
import {Button} from "react-bootstrap";
import {base_url, prompt_dict} from "../index";
import TopImage from "./TopImage";
import axios from "axios";
import cart from "../assets/Images/cart.svg"

// The image grid
const ImageGridOutput = ({currentImages, onImageSelect, design_descript}) => {
    console.log("IMAGEGRIDOUTPUT")

    const [isHovered, setIsHovered] = useState(false);

    const createButtonHandler = (event, src, alt) => {
        setIsHovered(false);
        onImageSelect(event, src, alt);
    };

    if (currentImages.length === 0) {
        currentImages = prompt_dict["Psychedelic anteater"]
        localStorage.setItem('currentImages', JSON.stringify(currentImages));
        localStorage.setItem('design_desc', JSON.stringify("Psychedelic anteater"));
    }

    // TODO: try this method when deployed
    let imagesToRender = currentImages['compressed_images'] || currentImages;

    return (<div className="catlog_image-grid_creation">
        {imagesToRender.map((src, i) => (<div className="image-container" key={i}>
                <img
                    className="inside_image card_catalog_creation"
                    src={src}
                    // onClick={(event) => onImageSelect(event, src, currentImages["images"][i])}
                    alt={currentImages["images"][i]}
                    key={i}
                />
                {/*<div className="overlay" onMouseEnter={() => setIsHovered(true)}*/}
                {/*     onMouseLeave={() => setIsHovered(false)}>*/}
                {/*    {isHovered && (*/}
                {/*        <Button className="create-button"*/}
                {/*                onClick={(event) => createButtonHandler(event, src, currentImages["images"][i])}>*/}
                {/*            Create Products*/}
                {/*        </Button>*/}
                {/*    )}*/}
                {/*</div>*/}
            </div>
        ))}
    </div>);
}

const ProductGridOutput = ({currentImages, onImageSelect, design_descript}) => {
    console.log("IMAGEGRIDOUTPUT")
    console.log(currentImages["all_products"])

    if (!currentImages || !currentImages.all_products) {
        return null;
      }

    const [isHovered, setIsHovered] = useState(false);

    const createButtonHandler = (event, src, alt) => {
        setIsHovered(false);
        onImageSelect(event, src, alt);
    };

    function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i
        [array[i], array[j]] = [array[j], array[i]]; // swap elements
    }
}

    if (currentImages.length === 0) {
        currentImages = prompt_dict["Psychedelic anteater"]
        localStorage.setItem('currentImages', JSON.stringify(currentImages));
        localStorage.setItem('design_desc', JSON.stringify("Psychedelic anteater"));
    }

    // TODO: try this method when deployed
    let imagesToRender = currentImages["all_products"] || [];
    shuffleArray(imagesToRender)
    // let newArray = imagesToRender.flatMap((element) => [element, element, element, element, element]);
    console.log("IMAGES RENDER", imagesToRender)
    let ar = {
        "Square Stickers": "Stickers",
        "Puzzle (120, 252, 500-Piece)": "Puzzle",
        "Postcards (7 pcs)": "Postcards",
        "Gallery Canvas Wraps, Horizontal Frame": "Framed canvas"
    }
    return (<div className="product_image-grid_creation">
        {imagesToRender.map((product, i) => (<div className="image-container" key={i}>
                <img
                    className="inside_image card_catalog_creation"
                    src={`data:image/jpeg;base64,${product.images[0].src}`}
                    alt={currentImages["images"][i]}
                    key={i}
                />
            <br/>{<b>{ar[product.description]}</b>}<br/>Starting at ${product.variants.reduce((lowest, item) => {
                  if (lowest === null || item.price < lowest) {
                    return item.price;
                  } else {
                    return lowest;
                  }
                }, null)/100}
                <img className="cart" src={cart}/>
            </div>
        ))}
    </div>);
}

// Wrapper around image grid with extra functionality
export function ImageGrid({currentImages, product_titles_dict, changeTab}) {
    console.log("IMAGE GRID render")
    console.log("current Images", currentImages)

    const [is_top, setIsTop] = useState(false);
    const [selected_image, setImage] = useState("none");
    const [noncompressed_image, setNonCompressedImage] = useState("none");
    const [chosenProduct, setChosenProduct] = useState(null)

    // Select an image
    const onImageSelect = (event, src, alt) => {
        setImage(src);
        setNonCompressedImage(alt)
        setIsTop(true);
    };

    useEffect(() => {
        console.log("image grid mount")
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                setIsTop(false)
            }
        };
        window.addEventListener('keydown', handleEsc);
        return () => {
            window.removeEventListener('keydown', handleEsc);
            console.log("image grid umount")
        };
    }, []);


    let design_descript = JSON.parse(localStorage.getItem('design_desc')) || [];

    const topImage =
        <TopImage
            product_titles_dict={product_titles_dict}
            selected_image={selected_image}
            noncompressed_image={noncompressed_image}
            is_top={is_top}
            setIsTop={setIsTop}
            top="none"
            chosenProduct={chosenProduct}
            setChosenProduct={setChosenProduct}
        />

    const imageGridOutput = useMemo(() => (
        <ImageGridOutput
            currentImages={currentImages}
            onImageSelect={onImageSelect}
            design_descript={design_descript}
        />
    ), [currentImages, design_descript]);

    const prodcutGridOutput = useMemo(() => (
        <ProductGridOutput
            currentImages={currentImages}
            onImageSelect={onImageSelect}
            design_descript={design_descript}
        />
    ), [currentImages, design_descript]);

    return (
        <div className="surrounder">
            {topImage}
            <div className="design_text"></div>
            {imageGridOutput}
            {prodcutGridOutput}
        </div>
    )

}

export default memo(ImageGridOutput)