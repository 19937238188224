import React from "react";
import "./SiteMap.css";

const ComingSoon = () => {
    return (
        <div className="noimages">
            COMING SOON
        </div>
    );
};

export default ComingSoon;