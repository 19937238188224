import './Catalog.css';
import '../../Project.css'
import React, {useEffect, useState} from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import {base_url} from "../../index";
import logo from "../../assets/Images/loading-loader.svg";
import close from "../../assets/Images/close.svg";

const name_to_blueprint = {
    "T-shirt": 6, "Sticker": 384, "Canvas": 193, "Postcard": 523, "Puzzle": 616
};

const monthMapping = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
};

function Sortbar({num_products, marketplace, setMarketPlace, get_data, sort, setSort}) {
    console.log("SORTBAR")
    return (<div className="CatalogSortbar">
        <div className="InsideSortbarLeft">
            <div>Catalog</div>
            <div id="products">{num_products} {num_products === 1 ? "image" : "images"}</div>
        </div>
        <div className="InsideSortbarRight">
            <div className="SortbarGroup">
                <div className="CatalogSortbarItem">Sort by:</div>
                <DropdownButton className="CardBasic" title={sort} variant='secondary'>
                    <Dropdown.Item onClick={event => {
                        setSort("New to Old");
                    }}>New to Old</Dropdown.Item>
                    <Dropdown.Item onClick={event => {
                        setSort("Old to New");
                    }}>Old to new</Dropdown.Item>
                </DropdownButton>
            </div>
        </div>
    </div>)
}

function Catalog({delay}) {
    console.log("CATALOG")

    const [loaded, setLoaded] = React.useState(true);
    const [num_products, setNumProducts] = useState(0);
    const [allImages, setAllImages] = useState(null);
    const [allImageDict, setallImageDict] = useState({});
    const [images, setImages] = useState([]);
    const [sort, setSort] = useState("New to Old");
    const [product, setProduct] = useState("All");
    const [marketplace, setMarketPlace] = useState("Mine");
    const [updateFormat, setUpdateFormat] = useState(0);
    const [showbar, setShowBar] = useState(false);
    const [imgproducts, setimgproducts] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [recordsPerPage] = useState(9);
    const [index, setIndex] = useState(0);
    const [chosenProduct, setChosenProduct] = useState(null)
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        if (delay === true) {
            setTimeout(() => {
                get_data("Mine");
            }, 5500);
        } else {
            get_data("Mine");
        }
    }, []);

    async function get_data(marketplace) {
        console.log("GETTING DATA")
        let email = JSON.parse(localStorage.getItem('email'));
        let r = null
        if (marketplace === "Mine") {
            r = await fetch(`${base_url}/user_products/${email}`);
        } else {
            r = await fetch(`${base_url}/all_products`);
        }
        let allimages = await r.json();
        setAllImages(allimages['all']);
        setallImageDict(allimages['image_dict'])
        setUpdateFormat(updateFormat + 1);
    }

    function format_data() {
        if (allImages === null) {
            return
        }
        let sortedImages = allImages;
        setNumProducts(sortedImages.length);
        sortedImages = [...sortedImages].sort((a, b) => {
            const [weekday, day, month, year, time, timezone] = a.call_time.split(" ");
            const [weekdayb, dayb, monthb, yearb, timeb, timezoneb] = b.call_time.split(" ");

            const safariFriendlyDateStringA = `${year}-${monthMapping[month]}-${day}T${time}`;
            const safariFriendlyDateStringB = `${yearb}-${monthMapping[monthb]}-${dayb}T${timeb}`;
            if (sort === "Old to New") {
                return new Date(safariFriendlyDateStringA) - new Date(safariFriendlyDateStringB)
            } else {
                return new Date(safariFriendlyDateStringB) - new Date(safariFriendlyDateStringA)
            }
        });
        setImages(sortedImages);
        if (sortedImages.length > 0) {
            setShowBar(true);
        }
        setCurrentPage(0);
    }


    function ShopifyProductBlock() {

        const [is_top, setIsTop] = useState(false);
        const [selected_image, setImage] = useState("none");

        useEffect(() => {
            const handleEsc = (event) => {
                if (event.keyCode === 27) {
                    setIsTop(false)
                }
            };
            window.addEventListener('keydown', handleEsc);
            return () => {
                window.removeEventListener('keydown', handleEsc);
            };
        }, []);

        function nextPage() {
            let max_page = Math.floor(num_products / recordsPerPage);
            if (currentPage !== max_page) {
                setCurrentPage(currentPage + 1);
                window.scrollTo(0, 0);
            }
        }

        function previousPage() {
            if (currentPage !== 0) {
                setCurrentPage(currentPage - 1)
                window.scrollTo(0, 0);
            }
        }

        async function imageSelect(event) {
            var elements = document.getElementsByClassName("InsideDesignImageGrid PictureFrame");
            for (var i = 0; i < elements.length; i++) {
                if (elements[i].src === event.target.src) {
                    setImage(event.target.src);
                    let t = allImageDict[event.target.src.replace(/%20/g, ' ')];
                    let r = await fetch(`${base_url}/printify2`, {
                        method: 'POST', headers: {
                            'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'
                        }, body: JSON.stringify({
                            'product_ids': t,
                        })
                    });
                    let r2 = await r.json()
                    console.log("CHOSEN PRODUCT", r2)
                    setimgproducts(r2);
                    setIsTop(true);
                }
            }
        }

        function checkBoxActions(event) {
            let checkboxes = document.getElementsByClassName("ProductCheckbox");
            let on = event.target.checked;
            for (let i = 0; i < checkboxes.length; i++) {
                if (checkboxes[i].name !== event.target.name) {
                    checkboxes[i].checked = false
                } else {
                    if (on) {
                        setChosenProduct(i);
                    } else {
                        setChosenProduct(null)
                    }
                }
            }
        }

        function topImage() {

            if (is_top) {
                const findOptionTitle = (chosenProduct !== null) ? (optionId) => {
                    for (const option of imgproducts[chosenProduct].options) {
                        for (const value of option.values) {
                            if (value.id === optionId) {
                                return value.title;
                            }
                        }
                    }
                    return null;
                } : null
                const headerRow = (chosenProduct !== null) ? (<tr>
                    {imgproducts[chosenProduct].options.map((option) => (<th key={option.name}>{option.name}</th>))}
                    <th>Price</th>
                </tr>) : null

                const rows = (chosenProduct !== null) ? imgproducts[chosenProduct].variants.map((variant) => {
                    return (<tr key={variant.id}>
                        {variant.options.map((optionId, i) => {
                            if (variant.is_available === true) {
                                if (imgproducts[chosenProduct].options[i].name === "Colors") {
                                    return (<td key={optionId}>{findOptionTitle(optionId)}</td>)
                                } else {
                                    return (<td key={optionId}>{findOptionTitle(optionId)}</td>)
                                }
                            }
                        })}
                        <td>${variant.price / 100}</td>
                    </tr>)
                }) : null
                return (<div className="ShowingProduct">
                    <div className="s">
                        {chosenProduct === null ? <div className='DesignCardL'>
                            <img className='DesignImage' src={selected_image} alt='LoginImage'/>
                        </div> : <div className='DesignCardL'>
                            <div className={"DesignImage"}><Carousel
                                activeIndex={index}
                                onSelect={handleSelect}
                                variant='dark'
                                slide={false}
                                interval={null}
                                className='Left'
                            >
                                {imgproducts[chosenProduct].images.slice(0, 6).map(image => <Carousel.Item>
                                    <Card.Img variant="top" src={image.src}/>
                                </Carousel.Item>)}
                            </Carousel></div>
                        </div>}
                        <div className='DesignCardR'>
                            <div className='DesignCardInnerR'>
                                <img className="CloseButton" src={close}
                                     onClick={event => {
                                         setIsTop(false)
                                         setChosenProduct(null)
                                     }}
                                     alt={"CloseButton"}
                                />
                                <div>
                                    <div className="ProductTitle2">{imgproducts[0].title}</div>
                                    <div className="DesignButtons">
                                        {imgproducts.map((title, i) => (<div className="ProductCheckboxGroup">
                                            <input className="ProductCheckbox" type="checkbox"
                                                   name={title.description.split(':')[0]} onClick={event => {
                                                checkBoxActions(event);
                                            }}
                                            />
                                            <div className="ProductTitle">{title.description.split(':')[0]}</div>
                                        </div>))}
                                    </div>
                                </div>
                                {chosenProduct !== null ? <div className='LoginButton CatalogButton OTHER'>
                                    <a
                                        className="buy"
                                        href={imgproducts[chosenProduct].external ? imgproducts[chosenProduct].external.handle : null}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        onClick={() => {
                                            if (!imgproducts[chosenProduct].external) {
                                                window.alert("Product has not been published yet, please check back in a few minutes.");
                                            }
                                        }}
                                    >Buy Now
                                    </a>
                                </div> : null}
                            </div>
                        </div>
                        {chosenProduct != null ? <div className="table-container">
                            <table>
                                <thead>{headerRow}</thead>
                                <tbody>{rows}</tbody>
                            </table>
                        </div> : null}
                    </div>
                </div>)
            }
        }

        useEffect(() => {
            format_data();
        }, [product, sort, updateFormat]);

        Array.prototype.move = function (from, to) {
            this.splice(to, 0, this.splice(from, 1)[0]);
        };
        let imggrid = null;
        if (images.length > 0 && loaded === true) {
            imggrid = images.slice(currentPage * recordsPerPage, (currentPage + 1) * recordsPerPage).map((product, productIndex) => {
                productIndex = productIndex + currentPage * recordsPerPage;
                return (<img
                    className="InsideDesignImageGrid PictureFrame"
                    onClick={event => imageSelect(event)}
                    src={product['image']}
                />)
            })
        }
        if (allImages !== null) {
            return (<div>
                {topImage()}
                {showbar && images.length > 0 ? <div>
                    <div className="CatalogImageGrid">
                        {imggrid}
                    </div>
                    <div className="Pagination">
                        <button className="LoginButton B2" onClick={previousPage}>Previous</button>
                        <button className="LoginButton B2" onClick={nextPage}>Next</button>
                    </div>
                </div> : <div className="noimages">NO PRODUCTS</div>}
            </div>)
        } else {
            return (<div>
                <img src={logo} className="SpinningWheel SW2" alt="SpinningWheel"/>
            </div>)
        }
    }

    return (<div>
        <Sortbar
            num_products={num_products}
            marketplace={marketplace}
            setMarketPlace={setMarketPlace}
            get_data={get_data}
            sort={sort}
            setSort={setSort}
        />
        <div>
            {ShopifyProductBlock()}
        </div>
    </div>)
}


export default Catalog;
