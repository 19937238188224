import './About.css';
import '../../Project.css'
import React from "react";
import {SocialIcon} from "react-social-icons";
import puzzle from '../../assets/Images/puzzle.jpeg'
import sticker from '../../assets/Images/anteater.jpeg'
import tshirt from '../../assets/Images/tshirt.jpeg'
import Creation from "../Creation/Creation";
import { memo } from "react";
function About({changeTab}) {
    return (<div>
            <div className='Info'>
                <div className="Graphic">
                    <div className="AboutAbout">About</div>
                    <div className="AboutInfo">
                        The AI product generator. Type any prompt you can imagine and get one of a kind AI generated
                        images,
                        design and place the images on products you wish to buy, then get them dropshipped to your
                        house! Visit the <a className={"AboutLink"} href="/"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                changeTab(<Creation changeTab={changeTab} />, 0);
                                                window.scrollTo({
                                                    top: 0, left: 0, behavior: 'instant'
                                                });
                                            }}
                    >Studio</a> page to get started.
                    </div>
                    <div className="social-media" style={{'margin-top': '4vh'}}>
                        <SocialIcon url="https://twitter.com/AibyuLabs?s=20" target="_blank" rel="noopener noreferrer"/>
                        <SocialIcon url="https://www.instagram.com/aibyulabs/" target="_blank"
                                    rel="noopener noreferrer"/>
                        <SocialIcon url="mailto:aibyulabs@gmail.com " target="_blank" rel="noopener noreferrer"/>
                    </div>
                    <div>
                        <div>
                            <img
                                className={"images"}
                                src={'https://s3.amazonaws.com/aibyu/user_images/cdb6b655-2e82-4179-b1e3-7592b031b1b8/cdb6b655-2e82-4179-b1e3-7592b031b1b8_2023-03-17 23:22:03.813455_0_compressed.png'}
                                alt={"sticker"}
                            />
                            &nbsp; &nbsp; -----------> &nbsp; &nbsp;
                            <img
                                className={"images"}
                                src={sticker}
                                alt={"sticker"}
                            />
                        </div>
                        <div>
                            <img
                                className={"images"}
                                src={'https://s3.amazonaws.com/aibyu/user_images/efff0142-b8d5-4286-a065-eca27030fc5d/efff0142-b8d5-4286-a065-eca27030fc5d_2023-03-19 17:28:46.609099_0_compressed.png'}
                                alt={"sticker"}
                            />
                            &nbsp; &nbsp; -----------> &nbsp; &nbsp;
                            <img
                                className={"images"}
                                src={puzzle}
                                alt={"sticker"}
                            />
                        </div>
                        <div>
                            <img
                                className={"images"}
                                src={'https://s3.amazonaws.com/aibyu/user_images/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8/8431fbb2-ed4b-4801-a0d9-9fbad49c40d8_2023-04-22 16:13:49.765071_0_compressed.png'}
                                alt={"sticker"}
                            />
                            &nbsp; &nbsp; -----------> &nbsp; &nbsp;
                            <img
                                className={"images"}
                                src={tshirt}
                                alt={"sticker"}
                            />
                        </div>

                    </div>
                </div>
            </div>
        </div>)
}


export default memo(About);
