import './Creation.css';
import '../../Project.css';
import React, {useCallback, useEffect, useState} from 'react';
import {base_url, prompt_dict} from '../../index';
import 'bootstrap/dist/css/bootstrap.css';
import {Product} from '../../components/HelperComponents';
import {ImageGrid} from '../../components/ImageGrid';
import { memo } from "react";
import SubmissionComponent from "../../components/Submission";
// Inner creation component
function CreationInner({
                           changeTab,
                           product_titles_dict,
                           randomElement1,
                           randomElement2,
                       }) {

    console.log("Creation inner has rendered")

    // State for current images
    const [currentImages, setCurrentImages] = useState(
        JSON.parse(localStorage.getItem('currentImages')) || []
    );

    function setInitialOutputs() {
        let outputs = JSON.parse(localStorage.getItem('currentImages')) || [];
        if (outputs.length === 0) {
            localStorage.setItem('currentImages', JSON.stringify(prompt_dict["Psychedelic anteater"]));
            localStorage.setItem('design_desc', JSON.stringify("Psychedelic anteater"));
            setCurrentImages(prompt_dict["Psychedelic anteater"]);
        }
    }

    // Set initial outputs on component mount
    useEffect(() => {
        console.log('Creation inner mounted');
        setInitialOutputs();
         return () => {
    console.log('Creation inner unmounted');
  };
    }, []);


    // Handle output change callback
    const handleOutputChange = useCallback((newOutput) => {
        setCurrentImages(newOutput);
    }, []);

    return (
        <div className="Wrapper">
            <SubmissionComponent
                onOutputChange={handleOutputChange}
                randomElement1={randomElement1}
                randomElement2={randomElement2}
                // currentImages={currentImages}
                product_titles_dict={product_titles_dict}
                // changeTab={changeTab}
            />
            <ImageGrid
                currentImages={currentImages}
                product_titles_dict={product_titles_dict}
                changeTab={changeTab}
            />
        </div>
    );
}

// Outer creation component
function Creation({changeTab}) {
    console.log('CREATION OUTER has render');
    let randomElement1 = Math.floor(Math.random() * 19);
    let randomElement2 = Math.floor(Math.random() * 19);

    // State for product titles dictionary
    const [product_titles_dict, setProductTitlesDict] = useState([]);

    useEffect(() => {
  console.log('Creation Outer mounted');
  return () => {
    console.log('Creation Outer unmounted');
  };
}, []);

    // Fetch Printify products on component mount
    useEffect(() => {
        getPrintifyProducts();
    }, []);

    // Fetch products from Printify API and create a dictionary
    function getPrintifyProducts() {
        console.log("SEETTTINGGG")
      fetch(`${base_url}/printify/products`)
        .then((resp) => resp.json())
        .then((apiarr) => {
          let product_dict = {};
          for (let i = 0; i < apiarr.length; i++) {
            let prod = new Product(apiarr[i][0], apiarr[i][1]);
            product_dict[prod.title] = prod;
          }
          setProductTitlesDict(product_dict);
          console.log(product_dict);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }

    return (
        <CreationInner
            changeTab={changeTab}
            product_titles_dict={product_titles_dict}
            randomElement1={randomElement1}
            randomElement2={randomElement2}
        />
    );
}

export default memo(Creation);