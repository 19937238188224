import './App.css'
import Creation from "../Creation/Creation";
import Catalog from "../Catalog/Catalog";
import About from "../About/About";
import React, {useEffect, useState} from "react";
import SiteMap from "../../components/Sitemap";
import ImageGallery from "../ImageGallery/ImageGallery";
import ComingSoon from "../../components/ComingSoon";
import HamburgerMenu from "../../components/HamburgerMenu";

function App() {

    console.log("APP")


    // Components dictionary for easy access
    let dict = {
        0: <Creation changeTab={changeTab} />,
        1: <ImageGallery/>,
        2: <Catalog/>,
        3: <About changeTab={changeTab} />,
        4: <ComingSoon/>
    }

    const [tab, setTab] = useState(dict[3]);
    const [style, setStyle] = useState([{'font-weight': 'lighter'}, {'font-weight': 'lighter'}, {'font-weight': 'lighter'}, {'font-weight': 'normal'},]);

    function generateUUID() { // Public Domain/MIT
        var d = new Date().getTime();//Timestamp
        var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16;//random number between 0 and 16
            if (d > 0) {//Use timestamp until depleted
                r = (d + r) % 16 | 0;
                d = Math.floor(d / 16);
            } else {//Use microseconds since page-load if supported
                r = (d2 + r) % 16 | 0;
                d2 = Math.floor(d2 / 16);
            }
            return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
    }

    // Return to login page if email element is not set
    useEffect(() => {
        let email = JSON.parse(localStorage.getItem('email')) || '';
        let length = email.length === 0;
        if (length) {
            let use_email = generateUUID();
            localStorage.setItem('email', JSON.stringify(use_email));
        }
        let currenttab = JSON.parse(localStorage.getItem('currenttab')) || '';
        if (currenttab === "") {
            localStorage.setItem('currenttab', 3)
            changeTab(dict[3], 3)
        } else {
            changeTab(dict[currenttab], currenttab)
        }
    }, []);

    function changeTab(component, num) {
        setTab(component);
        localStorage.setItem('currenttab', num)
        let temp = style;
        for (let i = 0; i < temp.length; i++) {
            if (i === num) {
                temp[i] = {'font-weight': 'normal'}
            } else {
                temp[i] = {'font-weight': 'lighter'}
            }
        }
        setStyle(temp);
    }

    const headerTabs = [{
        title: "Studio", component: <Creation changeTab={changeTab} />, className: "left"
    },
        {
        title: "Community", component: <ImageGallery/>, className: "left"
    }, {
        title: "Catalog", component: <Catalog/>, className: "left"
    }
    ];

    return (<div>
        <header>
            <div id="HeaderWrapper">
                <div className="InnerHeader LeftInnerHeader">
                    {headerTabs.map((tab, index) => (<>
                        <div className="Inner" style={style[index]}>
                            <a
                                style={{'text-decoration': 'none'}}
                                className='tab'
                                onClick={() => {
                                    changeTab(tab.component, index);
                                }}
                            >
                                <span className={tab.className}>{tab.title}</span>
                            </a>
                        </div>
                        {index < headerTabs.length - 1 && <div className="line"/>}
                    </>))}
                </div>
                <div className="InnerHeader CenterInnerHeader">
                    <div>
                        <a
                            style={{'text-decoration': 'none'}}
                            className='tab'
                            id="creation"
                            onClick={() => {
                                changeTab(<Creation/>, 0);
                            }}
                        >
                            <span id="LogoName">AIBYU</span>
                        </a>
                    </div>
                </div>
                <div className="InnerHeader RightInnerHeader">
                    <div style={style[3]} className={"Showing"}>
                        <a
                            style={{'text-decoration': 'none'}}
                            className='tab'
                            id="about"
                            onClick={() => {
                                changeTab(<About changeTab={changeTab} />, 3);
                            }}
                        >
                            <span className="right">About</span>
                        </a>
                    </div>
                    <div style={{'font-weight': 'lighter', 'display': 'flex'}}>
                        <a style={{'text-decoration': 'none'}} href="/login">
                            {localStorage.getItem('loggedin') === '"f"' ? (<span className="right">Logout</span>) : (
                                <span className="right">Login</span>)}
                        </a>
                        <div>
                            <HamburgerMenu changeTab={changeTab} />
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div>
            <div style={{'min-height': '88vh'}}>{tab}</div>
            <SiteMap changeTab={changeTab} classname={"sitemap"}/>
        </div>
    </div>);

}

export default App;